<template>
  <b-container>
    <b-row class="mb-5 pt-5 d-flex justify-content-between text-start">
      <b-col><curva-title :title="$t('mainNav.products')"></curva-title></b-col>
<!--      <dropdown-btn @select="getSelected" />-->
    </b-row>
    <b-row id="offers-list" class="mb-5" v-if="bestSeller.length > 0">
      <b-col
          lg="3"
          md="6"
          sm="6"
          v-for="(product, key) in bestSeller"
          :key="key"
      >
        <product-card :product="product" />
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col class="d-flex justify-content-center">
        <b-pagination
            class="curva-pagination"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            @input="getAllTopProducts"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import aboutServices from '@/modules/about/services/about.services'
import { core } from '@/config/pluginInit'

export default {
  data () {
    return {
      homeData: [],
      bestSeller: [],
      sortValue: 'id&dir=desc',
      perPage: 0,
      currentPage: 1,
      rows: 1
    }
  },
  methods: {
    getAllTopProducts () {
      aboutServices.getAllTopProducts(this.currentPage).then(res => {
        this.bestSeller = res.data.data
        this.rows = res.data.meta.total
        this.perPage = res.data.meta.per_page
        this.currentPage = res.data.meta.current_page
      })
    }
    /* getSelected (value) {
      this.currentPage = 1
      this.sortValue = value
      this.getHomeData()
    } */
  },
  created () {
    this.getAllTopProducts()
  },
  mounted () {
    core.index()
  }
}
</script>
